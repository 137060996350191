import { currencyFormatGTM } from './formatter';
// import TagManager from 'react-gtm-module';
import { sendGTMEvent } from '@next/third-parties/google'
import jsSHA from 'jssha';
// import ReactGA from 'react-ga';
import productHelpers from './product';
import { ReactPixel } from '#/renderer/router';
//import blukai, removido
import { getConfigMC } from "~/store/Query/api";
import { CONEQUIPO, getPospagoState, SINEQUIPO } from '~/store/States/pospago';
import { CURRENCY, LOCALE, PLAN, TYPE_PLAN, TYPE_POSPAGO } from '~/store/States/global';

let sendDataLayer = true;
let dataLog = true;
const now = new Date();

if (process.env.NEXT_PUBLIC_PROD) {
  dataLog = false;
}

const userId = () => {
  const id = sessionStorage.getItem('userId');
  if (id) {
    // ReactGA.set({ userId: id });
  }
  return id;
};

function getPricePospago(item, attrMap) {
  const planDefault = attrMap['attr-conf-default-plan']?.replace(/\./g, '_');
  const plazoDefault = attrMap['attr-conf-default-plazo'];
  let prices = item.pricing.pospago?.[planDefault || ''];
  if (!prices) {
    const plan = Object.keys(item.pricing.pospago)
      .sort((p1, p2) => {
        let pl1 = plazoDefault;
        if (!pl1 || !item.pricing.pospago[p1][pl1]) {
          pl1 = Object.keys(item.pricing.pospago[p1])
            .sort((a, b) => b - a)[0];
        }
        let pl2 = plazoDefault;
        if (!pl2 || !item.pricing.pospago[p2][pl2]) {
          pl2 = Object.keys(item.pricing.pospago[p2])
            .sort((a, b) => b - a)[0];
        }

        return item.pricing.pospago[p1][pl1].offer_price - item.pricing.pospago[p2][pl2].offer_price;
      })[0];
    prices = item.pricing.pospago?.[plan];
  }

  if (prices) {
    let first = plazoDefault;
    if (!first || !prices[first]) {
      first = Object.keys(prices).sort((a, b) => b - a)[0];
    }
    return currencyFormatGTM(prices[first].offer_price, 1);
  }
  return null;
}

async function getItemInfo(item, attrMap, tipoProducto, tipoPospago, plan, plazo) {
  const itemType = item.type;
  const isPlan = itemType === TYPE_PLAN;
  const isPospago = itemType === TYPE_POSPAGO;
  const isSinEquipo = isPlan && tipoPospago === SINEQUIPO;

  const id = item.part_number;
  const brand = attrMap['filt-marca'] || '';
  const variant = `${attrMap['def-color'] || ''} ${attrMap['def-capacidad'] || ''} ${attrMap['def-pantalla'] || ''}`.trim();
  const name = ((isSinEquipo && attrMap['attr-conf-nombre-plan-sin-equipo']) || item.locales[LOCALE].name).replace(/<br>|<br\/>|\\n/g, '');
  const category = item.locales[LOCALE].categories[0].slug;
  let price;
  let currency = CURRENCY;
  if (isPlan) {
    const attr = (isSinEquipo && attrMap['attr-conf-precio-mensual-sin-equipo']) || attrMap['attr-conf-precio-mensual'];
    price = currencyFormatGTM(attr, 1);
  } else if (isPospago) {
    if (plan && plazo) {
      price = currencyFormatGTM(item.pricing.pospago[plan][plazo].offer_price, 1);
    } else {
      price = getPricePospago(item, attrMap);
    }
  } else {
    price = currencyFormatGTM(item.pricing[CURRENCY].offer_price);
  }

  let levelNumber = 2;
  if (isPlan || isPospago) {
    levelNumber = 3;
    const config = await getConfigMC();
    if (config.ok && config.body.monedaPospago.activo) {
        currency = config.body.monedaPospago.codigo;
    }
  }
  const level2 = item.locales[LOCALE].categories.find(category => category.level <= levelNumber);
  let label = level2?.name || '';
  if (isSinEquipo) {
    label = `${label} sin equipo`;
  }

  return ({
    id,
    name,
    price,
    brand,
    category,
    label,
    variant,
    currency,
  })
}

async function getCartItem(cart, partNumber) {
  const sku = partNumber.split('--')[0];
  const cartItem = cart.items.find(it => it.part_number === sku);
  const qty = cartItem.quantity;
  const item = cartItem.product.items[0];
  const itemType = item.type;
  const attrMap = productHelpers.convertAttributes(item.attributes);
  const tipoProducto = attrMap['attr-conf-tipoproducto'];
  const tipoPlan = attrMap['attr-conf-tipoplan']?.toUpperCase();
  let tipoPospago;
  let plan;
  let plazo;
  let priceExtra;
  let isDummy = false;
  if (itemType === TYPE_PLAN) {
    const pospagoState = await getPospagoState();
    tipoPospago = cart.items.some(it => it.part_number === pospagoState.config.product_sku_sin_equipo.value) ? SINEQUIPO : CONEQUIPO;
    const noIncluido = attrMap['attr-conf-todo-incluido']?.toUpperCase() === 'NO';
    if (noIncluido) {
      const kitItem = cart.items.find(it => it.part_number_kit);
      if (kitItem) {
        const sp = kitItem.part_number_kit.split('--');
        const kitPlan = sp[1];
        const kitPlazo = sp[2];
        priceExtra = (kitItem.product.items[0].pricing.pospago[kitPlan][kitPlazo].financing || 0) / kitPlazo;
      }
    }
  } else if (itemType === TYPE_POSPAGO) {
    const sp = cartItem.part_number_kit.split('--');
    plan = sp[1];
    plazo = sp[2];
    const pospagoState = await getPospagoState();
    isDummy = partNumber === pospagoState.config.product_sku_sin_equipo.value;
  }
  const itemInfo = await getItemInfo(item, attrMap, tipoProducto, tipoPospago, plan, plazo);
  if (priceExtra) {
    itemInfo.price = currencyFormatGTM(priceExtra + +itemInfo.price, 1);
  }
  return ({
    ...itemInfo,
    qty,
    tipoProducto,
    tipoPlan,
    isDummy,
  });
}

const analytics = {

  // Product Impressions
  productImpressions: async (products) => {
    if (!sendDataLayer) {
      return;
    }

    const items = [];
    if (products && products.length) {
      const pospagoState = await getPospagoState();
      const tipoPospago = pospagoState.tipo.value;
      for (let idx = 0; idx < products.length; idx++) {
        const product = products[idx];
        if (product.items.length) {
          productHelpers.ordenar(product);
          const item = product.items[0];
	      if (item) {
	        const attrMap = productHelpers.convertAttributes(item.attributes);
	        const tipoProducto = attrMap['attr-conf-tipoproducto'];
	        const {
	          id,
	          name,
	          price,
	          brand,
	          category,
	          label,
	          variant,
	        } = await getItemInfo(item, attrMap, tipoProducto, tipoPospago);

	        items.push({
	          'item_id': id,
	          'item_name': name,
	          'price': price,
	          'item_brand': brand,
	          'item_category': category,
	          'item_category2': label,
	          'item_variant': variant,
	          'item_list_id': tipoProducto,
	          'index': idx,
	          'quantity': '1'
	        });
          }
        }
      }
    }

    const dataLayerInfoGA4 = {
      'event': 'view_item_list',
      'ecommerce': {
        'items': items,
      }
    };

    sendGTMEvent({ ecommerce: null });
    sendGTMEvent(dataLayerInfoGA4);

    if (dataLog) {
      console.log('GTM view_item_list: ', dataLayerInfoGA4);
    }

  },

  // Product Click
  productClick: async (item, attrMap, tipoProducto, tipoPospago) => {
    if (!sendDataLayer) {
      return;
    }

    const items = [];
    const {
      id,
      name,
      price,
      brand,
      category,
      label,
      variant,
    } = await getItemInfo(item, attrMap, tipoProducto, tipoPospago);

    items.push({
      'item_id': id,
      'item_name': name,
      'price': price,
      'item_brand': brand,
      'item_category': category,
      'item_category2': label,
      'item_variant': variant,
      'item_list_id': tipoProducto,
      'index': 1,
      'quantity': '1',
    });

    const dataLayerInfoGA4 = {
      'event': 'select_item',
      'ecommerce': {
        'items': items,
      }
    };

    sendGTMEvent({ ecommerce: null });
    sendGTMEvent(dataLayerInfoGA4);
    if (dataLog) {
      console.log('GTM select_item: ', dataLayerInfoGA4);
    }

  },

  // Product Detail
  productDetail: async (item, attrMap) => {
    if (sendDataLayer) {
      const items = [];
      const tipoProducto = attrMap['attr-conf-tipoproducto'];
      let tipoPospago;
      if (tipoProducto === PLAN) {
        const pospagoState = await getPospagoState();
        tipoPospago = pospagoState.tipo.value;
      }
      const {
        id,
        name,
        price,
        brand,
        category,
        label,
        variant,
        currency,
      } = await getItemInfo(item, attrMap, tipoProducto, tipoPospago);

      items.push({
        'item_id': id,
        'item_name': name,
        'price': price,
        'item_brand': brand,
        'item_category': category,
        'item_category2': label,
        'item_variant': variant,
        'index': 1,
        'quantity': '1'
      });

      const viewcontentfb = {
        content_ids: id,
        content_type: 'product',
        value: price,
        currency: currency.toUpperCase(),
        product_catalog_id: tipoProducto,
        product_group: tipoProducto
      };

      ReactPixel.track('ViewContent', viewcontentfb);

      const dataLayerInfoGA4 = {
        'event': 'view_item',
        'ecommerce': {
          'items': items,
        }
      };

      sendGTMEvent(dataLayerInfoGA4);
      if (dataLog) {
        if (items[0].price >= 0 && items[0].price <= 1500 && items[0].item_brand !== "") {
          //blukai function, removida
        } else if (items[0].price >= 1500 && items[0].price <= 3000) {
          //blukai function, removida
        } else if (items[0].price >= 3000 && items[0].price <= 6000) {
          //blukai function, removida
        } else if (items[0].price >= 6000 && items[0].price <= 10000) {
          //blukai function, removida
        } else if (items[0].price >= 10000 && items[0].price <= 20000) {
          //blukai function, removida
        }
        console.log('GTM view_item: ', dataLayerInfoGA4);
      }
    }
  },

  // Add to NavCart
  addToCart: async (cart, partNumber) => {
    if (!sendDataLayer) {
      return
    }

    const items = [];
    const {
      id,
      name,
      price,
      brand,
      category,
      label,
      variant,
      currency,
      qty,
      tipoProducto,
    } = await getCartItem(cart, partNumber);

    const AddToCartfb = {
      content_ids: id,
      content_type: 'product',
      value: price,
      currency: currency.toUpperCase(),
      product_catalog_id: tipoProducto,
      product_group: tipoProducto
    };

    ReactPixel.track('AddToCart', AddToCartfb);

    items.push({
      'item_id': id,
      'item_name': name,
      'price': price,
      'item_brand': brand,
      'item_category': category,
      'item_category2': label,
      'item_variant': variant,
      'item_list_id': tipoProducto,
      'index': 1,
      'quantity': qty
    });

    const dataLayerInfoGA4 = {
      'event': 'add_to_cart',
      'ecommerce': {
        'items': items,
      }
    };
    sendGTMEvent({ ecommerce: null });
    sendGTMEvent(dataLayerInfoGA4);
    if (dataLog) {
      console.log('GTM add_to_cart: ', dataLayerInfoGA4);
    }

  },

  // Remove from NavCart
  removeFromCart: async (cart, partNumber) => {
    if (sendDataLayer) {
      const items = [];
      const {
        id,
        name,
        price,
        brand,
        category,
        variant,
        qty,
        tipoProducto,
        isDummy,
      } = await getCartItem(cart, partNumber);
      if (isDummy) {
        return;
      }

      items.push({
        'item_id': id,
        'item_name': name,
        'price': price,
        'item_brand': brand,
        'item_category': category,
        'item_variant': variant,
        'item_list_id': tipoProducto,
        'index': 1,
        'quantity': qty,
      });

      const dataLayerInfoGA4 = {
        'event': 'remove_from_cart',
        'ecommerce': {
          'items': items,
        }
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfoGA4);
      if (dataLog) {
        console.log('GTM removeFromCart: ', dataLayerInfoGA4);
      }
    }
  },

  // Promotion Impression
  promotionImpression: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'promotionImpression',
        'ecommerce': {
          'promoView': {
            'promotions': [{
              'id': 'id',
              'name': 'name',
              'creative': 'creative',
              'position': 'position',
            }]
          }
        },
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM promotionImpression: ', dataLayerInfo);
      }
    }
  },

  // Promotion Clicks
  promotionClick: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'promotionClick',
        'ecommerce': {
          'promoClick': {
            'promotions': [{
              'id': 'id',
              'name': 'name',
              'creative': 'creative',
              'position': 'position',
            }]
          }
        },
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM promotionClick: ', dataLayerInfo);
      }
    }
  },

  // Checkout
  checkout: async (step, cart) => {
    if (sendDataLayer && cart) {
      const itemsG4A = [];
      for (let i = 0; i < cart.items.length; i++) {
        const cartItem = cart.items[i];
        const {
          id,
          name,
          price,
          brand,
          category,
          label,
          variant,
          qty,
          tipoPlan,
          isDummy,
        } = await getCartItem(cart, cartItem.part_number);
        if (isDummy) {
          continue;
        }
        itemsG4A.push({
          'item_name': name,
          'item_id': id,
          'price': price,
          'item_brand': brand,
          'item_category': category,
          'item_category2': tipoPlan === 'SIM' ? name : label,
          'item_variant': variant,
          'quantity': qty,
        });
      }

      let nameEvent;

      switch (step) {
        case 1:
          nameEvent = 'begin_checkout';
          break;
        case 2:
          nameEvent = 'add_shipping_info';
          break;
        case 3:
          nameEvent = 'add_payment_info';
          break;
      }

      const dataLayerInfoGA4 = {
        'event': nameEvent,
        'ecommerce': {
          'items': itemsG4A,
        }
      };

      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfoGA4);
      if (dataLog) {
        console.log('GTM cart: ', dataLayerInfoGA4);
      }
    }
  },

  // Purchase
  purchase: async (orderInfo) => {
    if (sendDataLayer) {
      const transactionID = orderInfo.external_referer;
      const tax = orderInfo.amount[CURRENCY].total_sales_tax;
      const shipping = orderInfo.amount[CURRENCY].total_shipping_charge;
      const coupon = orderInfo.promo_codes?.length > 0 ? orderInfo.promo_codes : '';
      let total = 0;
      const purchasefbIte = [];
      const summaryG4A = [];
      for (let i = 0; i < orderInfo.items.length; i++) {
        const cartItem = orderInfo.items[i];
        const {
          id,
          name,
          price,
          brand,
          category,
          label,
          variant,
          qty,
          tipoPlan,
          isDummy,
        } = await getCartItem(orderInfo, cartItem.part_number);
        if (isDummy) {
          continue;
        }
        total += +price;
        purchasefbIte.push({
          'id': id,
          'quantity': qty,
        });
        summaryG4A.push({
          'item_name': name,
          'item_id': id,
          'price': price,
          'item_brand': brand,
          'item_category': category,
          'item_category2': tipoPlan === 'SIM' ? name : label,
          'item_variant': variant,
          'quantity': qty,
        });
      }
      const revenue = currencyFormatGTM(total, 1);

      const purchasefb = {
        value: revenue,
        currency: CURRENCY.toUpperCase(),
        contents: purchasefbIte,
        content_type: 'product'
      };

      ReactPixel.track('Purchase', purchasefb);

      const dataLayerInfoGA4 = {
        'event': 'purchase-ga4',
        'ecommerce': {
          'transaction_id': transactionID, // Transaction ID. Required for purchases and refunds.
          'affiliation': 'User',
          'value': revenue, // Total transaction value (incl. tax and shipping)
          'tax': tax,
          'shipping': shipping,
          'currency': CURRENCY,
          'coupon': coupon,
          'items': summaryG4A,
        },
      };

      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfoGA4);
      if (dataLog) {
        console.log('GTM purchase: ', dataLayerInfoGA4);
      }
    }
  },

  // Refund
  refund: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'ecommerce': {
          'refund': {
            'actionField': { 'id': 'T12345' } // Transaction ID. Required for purchases and refunds.
          }
        }
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM refund: ', dataLayerInfo);
      }
    }
  },

  // Partial Refund
  refundPartial: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'ecommerce': {
          'refund': {
            'actionField': { 'id': 'T12345' }, // Transaction ID. Required for purchases and refunds.
            'products': [
              {
                'id': 'P4567',
                'quantity': 1
              }, // Product ID and quantity. Required for partial refunds.
              {
                'id': 'P8901',
                'quantity': 2
              }
            ]
          }
        }
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM refundPartial: ', dataLayerInfo);
      }
    }
  },

  errorPago: (paymentResponse) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'error_pago',
        'resultCode': paymentResponse.responseCode,
        'bankResponseCode': paymentResponse.bankResponseCode,
        'bankResponseText': paymentResponse.bankResponseText,
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM errorPago: ', dataLayerInfo);
      }
    }
  },

  usuarioCreado: (firstName, lastName) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'usuario_creado',
        'firstName': firstName,
        'lastName': lastName,
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM usuarioCreado: ', dataLayerInfo);
      }

    }
  },

  Aceptacion_Terminos_Contratos_Postapago: (orderId, terminos) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'Aceptacion_Terminos_Contratos_Postapago',
        'orderId': orderId,
        'fecha': now.toLocaleDateString(),
        'nameTermino': terminos,
      };
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM Aceptacion_Terminos_Contratos_Postapagoo: ', dataLayerInfo);
      }

    }
  },

  Evento_Postapago: (orderId, NombreEvento) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': NombreEvento,
        'orderId': orderId,
        'fecha': now.toLocaleDateString(),
      };
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM ' + NombreEvento, dataLayerInfo);
      }
    }
  },

  Descarga_Contrato_Postapago: (orderId, contracto) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'Descarga_Contratos_Postapago',
        'orderId': orderId,
        'fecha': now.toLocaleDateString(),
        'contrato': contracto,
      };
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM Descarga_Contratos_Postapago', dataLayerInfo);
      }
    }
  },

  Lead_Exitoso_Fijo: (data) => {
    if (sendDataLayer) {
      let items = [];

      items.push({
        'item_name': data.producto.nombre,
        'item_id': data.producto.id,
        'price': data.producto.precio,
        'item_brand': 'Claro',
        'item_category': 'Claro Hogar',
        'item_variant': 'solicitud-servicio-fijos',
        'quantity': 1
      });

      const dataLayerInfo = {
        'event': 'solicitud-servicio-fijos',
        'producto': data.producto.nombre,
        'detalle_solicitud': items,
        'addon': data.addons,
        'montototal': data.montototal,
        'fecha': now.toLocaleDateString(),

      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM solicitud', dataLayerInfo);
      }
    }
  },

  Lead_Exitoso_Renovacion: (data) => {
    if (sendDataLayer) {
      let items = [];

      items.push({
        'item_name': 'renovacion',
        'item_id': 'renovacion',
        'price': '0',
        'item_brand': 'Claro',
        'item_category': data,
        'item_variant': 'solicitud-renovacion',
        'quantity': 1
      });

      const dataLayerInfo = {
        'event': 'solicitud-renovacion',
        'producto': 'renovacion',
        'detalle_solicitud': items,
        'fecha': now.toLocaleDateString(),

      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM solicitud', dataLayerInfo);
      }
    }
  },

  hashUserId: (email) => {
    email = email.toLowerCase();
    const sha256 = new jsSHA('SHA-256', 'TEXT');
    sha256.update(email);
    const hash = sha256.getHash('HEX');
    sessionStorage.setItem('userId', hash);
  },

  userId: userId,
  login: () => {
    const id = userId();
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'loginSuccess',
        'user_id': id,
      };
      sendGTMEvent({ ecommerce: null });
      sendGTMEvent(dataLayerInfo);
      if (dataLog) {
        console.log('GTM login: ', dataLayerInfo);
      }

    }
  },

  logout: () => {
    // ReactGA.set({ userId: null });
    const id = sessionStorage.getItem('userId');
    if (id) {
      sessionStorage.removeItem('userId');
    }
  },
};

export default analytics;
